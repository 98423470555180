import React from "react";
import { Helmet } from "react-helmet-async";
// import "./style.css";

import EventContainer from "../../layouts/eventcontainer/eventContainer";
import InternationalConference_Img_1 from "../../../assets/images/gallery/2024/confernce/Img  (1).JPG";
import InternationalConference_Img_2 from "../../../assets/images/gallery/2024/confernce/Img  (2).jpg";
import InternationalConference_Img_3 from "../../../assets/images/gallery/2024/confernce/Img  (3).jpg";
import InternationalConference_Img_4 from "../../../assets/images/gallery/2024/confernce/Img  (4).jpg";
import InternationalConference_Img_5 from "../../../assets/images/gallery/2024/confernce/Img  (5).jpg";
import InternationalConference_Img_6 from "../../../assets/images/gallery/2024/confernce/Img  (6).jpg";

import PongalFestival_Img_1 from "../../../assets/images/gallery/2024/PongalFestival/Img (1).jpg";
import PongalFestival_Img_2 from "../../../assets/images/gallery/2024/PongalFestival/Img (2).jpg";
import PongalFestival_Img_3 from "../../../assets/images/gallery/2024/PongalFestival/Img (3).jpg";
import PongalFestival_Img_4 from "../../../assets/images/gallery/2024/PongalFestival/Img (4).jpg";
import PongalFestival_Img_5 from "../../../assets/images/gallery/2024/PongalFestival/Img (5).jpg";
import PongalFestival_Img_6 from "../../../assets/images/gallery/2024/PongalFestival/Img (6).jpg";
import PongalFestival_Img_7 from "../../../assets/images/gallery/2024/PongalFestival/Img (7).jpg"; 

import PosterPresention_Img_1 from "../../../assets/images/gallery/2024/PosterPresentations/img_1.JPG";
import IndustrialVisit_Img_1 from "../../../assets/images/gallery/2024/IV Bcom department/img_1 (1).JPG";

import FoodFestival_Img_1 from "../../../assets/images/gallery/2024/foodfestival/Img (1).JPG";
import FoodFestival_Img_2 from "../../../assets/images/gallery/2024/foodfestival/Img (9).JPG";
import FoodFestival_Img_3 from "../../../assets/images/gallery/2024/foodfestival/Img (3).JPG";
import FoodFestival_Img_4 from "../../../assets/images/gallery/2024/foodfestival/Img (4).JPG";
import FoodFestival_Img_5 from "../../../assets/images/gallery/2024/foodfestival/Img (5).JPG";
import FoodFestival_Img_6 from "../../../assets/images/gallery/2024/foodfestival/Img (6).JPG";
import FoodFestival_Img_7 from "../../../assets/images/gallery/2024/foodfestival/Img (7).JPG";
import FoodFestival_Img_8 from "../../../assets/images/gallery/2024/foodfestival/Img (8).JPG";

import YogaDay_Img_1 from "../../../assets/images/gallery/2024/yoga day/Img (1).JPG";
import YogaDay_Img_2 from "../../../assets/images/gallery/2024/yoga day/Img (2).JPG";
import YogaDay_Img_3 from "../../../assets/images/gallery/2024/yoga day/Img (3).JPG";
import YogaDay_Img_4 from "../../../assets/images/gallery/2024/yoga day/Img (4).JPG";
import YogaDay_Img_5 from "../../../assets/images/gallery/2024/yoga day/Img (5).JPG";
import YogaDay_Img_6 from "../../../assets/images/gallery/2024/yoga day/Img (6).JPG";

import SportsDazzle_Img_1 from "../../../assets/images/gallery/2024/sports apirl/img (1).jpg";
import SportsDazzle_Img_2 from "../../../assets/images/gallery/2024/sports apirl/img (2).jpg";
import SportsDazzle_Img_3 from "../../../assets/images/gallery/2024/sports apirl/img (3).jpg";
import SportsDazzle_Img_4 from "../../../assets/images/gallery/2024/sports apirl/img (4).jpg";
import SportsDazzle_Img_5 from "../../../assets/images/gallery/2024/sports apirl/img (5).jpg";
import SportsDazzle_Img_6 from "../../../assets/images/gallery/2024/sports apirl/img (6).jpg";

import FoodFestivaljune_Img_1 from "../../../assets/images/gallery/2024/food fesitval june/Img (1).JPG";
import FoodFestivaljune_Img_2 from "../../../assets/images/gallery/2024/food fesitval june/Img (2).JPG";
import FoodFestivaljune_Img_3 from "../../../assets/images/gallery/2024/food fesitval june/Img (3).JPG";
import FoodFestivaljune_Img_4 from "../../../assets/images/gallery/2024/food fesitval june/Img (4).JPG";
import FoodFestivaljune_Img_5 from "../../../assets/images/gallery/2024/food fesitval june/Img (5).JPG";
import FoodFestivaljune_Img_6 from "../../../assets/images/gallery/2024/food fesitval june/Img (6).JPG";
import FoodFestivaljune_Img_7 from "../../../assets/images/gallery/2024/food fesitval june/Img (7).JPG";
import FoodFestivaljune_Img_8 from "../../../assets/images/gallery/2024/food fesitval june/Img (8).JPG";
import FoodFestivaljune_Img_9 from "../../../assets/images/gallery/2024/food fesitval june/Img (9).JPG";

import FoodFestivaljune1_Img_1 from "../../../assets/images/gallery/2024/food fesitval june/IMG (1).jpeg";
import FoodFestivaljune1_Img_2 from "../../../assets/images/gallery/2024/food fesitval june/IMG (2).jpeg";
import FoodFestivaljune1_Img_3 from "../../../assets/images/gallery/2024/food fesitval june/IMG (3).jpeg";
import FoodFestivaljune1_Img_4 from "../../../assets/images/gallery/2024/food fesitval june/IMG (4).jpeg";
import FoodFestivaljune1_Img_5 from "../../../assets/images/gallery/2024/food fesitval june/IMG (5).jpeg";
import FoodFestivaljune1_Img_6 from "../../../assets/images/gallery/2024/food fesitval june/IMG (6).jpeg";
import FoodFestivaljune1_Img_7 from "../../../assets/images/gallery/2024/food fesitval june/IMG (7).jpeg";
import FoodFestivaljune1_Img_8 from "../../../assets/images/gallery/2024/food fesitval june/IMG (8).jpeg";
import FoodFestivaljune1_Img_9 from "../../../assets/images/gallery/2024/food fesitval june/IMG (9).jpeg";

import UGInauguration_Img_1 from "../../../assets/images/gallery/2024/1 year inagurations/Img (1).jpg";
import UGInauguration_Img_2 from "../../../assets/images/gallery/2024/1 year inagurations/Img (2).jpg";
import UGInauguration_Img_3 from "../../../assets/images/gallery/2024/1 year inagurations/Img (3).jpg";
import UGInauguration_Img_4 from "../../../assets/images/gallery/2024/1 year inagurations/Img (7).jpg";
import UGInauguration_Img_5 from "../../../assets/images/gallery/2024/1 year inagurations/Img (5).jpg";
import UGInauguration_Img_6 from "../../../assets/images/gallery/2024/1 year inagurations/Img (6).jpg";

import UGOrientationProgramme_Img_1 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (1).jpeg";
import UGOrientationProgramme_Img_2 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (2).jpeg";
import UGOrientationProgramme_Img_3 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (3).jpeg";
import UGOrientationProgramme_Img_4 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (4).jpeg";
import UGOrientationProgramme_Img_5 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (5).jpeg";
import UGOrientationProgramme_Img_6 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (6).jpeg";
import UGOrientationProgramme_Img_7 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (7).jpeg";
import UGOrientationProgramme_Img_8 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (8).jpeg";
import UGOrientationProgramme_Img_9 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (9).jpeg";
import UGOrientationProgramme_Img_10 from "../../../assets/images/gallery/2024/1-Year UG Orientation Programme/Img  (10).jpeg";

import AwernessProgramm_Img_1 from "../../../assets/images/gallery/2024/women wellfare/img  (1).jpeg";
import AwernessProgramm_Img_2 from "../../../assets/images/gallery/2024/women wellfare/img  (2).jpeg";
import AwernessProgramm_Img_3 from "../../../assets/images/gallery/2024/women wellfare/img  (3).jpeg";
import AwernessProgramm_Img_4 from "../../../assets/images/gallery/2024/women wellfare/img  (4).jpeg";

import Kamaraj_Img_1 from "../../../assets/images/gallery/2024/kamarasar/Img (1).jpeg";
import Kamaraj_Img_2 from "../../../assets/images/gallery/2024/kamarasar/Img (2).jpeg";
import Kamaraj_Img_3 from "../../../assets/images/gallery/2024/kamarasar/Img (3).jpeg";
import Kamaraj_Img_4 from "../../../assets/images/gallery/2024/kamarasar/Img (4).jpeg";
import Kamaraj_Img_5 from "../../../assets/images/gallery/2024/kamarasar/Img (5).jpeg";
import Kamaraj_Img_6 from "../../../assets/images/gallery/2024/kamarasar/Img (6).jpeg";
import Kamaraj_Img_7 from "../../../assets/images/gallery/2024/kamarasar/Img (7).jpeg";

import ComputerApplication_Img_1 from "../../../assets/images/gallery/2024/poster prsentations/img (2).jpeg";
import EnglishDepartment_Img_1 from "../../../assets/images/gallery/2024/poster prsentations/img (1).jpeg";

import Microbiology_Img_1 from "../../../assets/images/gallery/2024/micro/IMG (1).jpeg";
import Microbiology_Img_2 from "../../../assets/images/gallery/2024/micro/IMG (2).jpeg";
import Microbiology_Img_3 from "../../../assets/images/gallery/2024/micro/IMG (3).jpeg";
import Microbiology_Img_4 from "../../../assets/images/gallery/2024/micro/IMG (4).jpeg";
import Microbiology_Img_5 from "../../../assets/images/gallery/2024/micro/IMG (5).jpeg";
import Microbiology_Img_6 from "../../../assets/images/gallery/2024/micro/IMG (6).jpeg";
import Microbiology_Img_7 from "../../../assets/images/gallery/2024/micro/IMG (7).jpeg";
import Microbiology_Img_8 from "../../../assets/images/gallery/2024/micro/IMG (8).jpeg";
import Microbiology_Img_9 from "../../../assets/images/gallery/2024/micro/IMG (9).jpeg";

import TamilDepartment_Img_1 from "../../../assets/images/gallery/2024/tamil department/Img (1).png";
import TamilDepartment_Img_2 from "../../../assets/images/gallery/2024/tamil department/Img (2).jpeg";
import TamilDepartment_Img_3 from "../../../assets/images/gallery/2024/tamil department/Img (3).jpeg";
import TamilDepartment_Img_4 from "../../../assets/images/gallery/2024/tamil department/Img (4).jpeg";
import TamilDepartment_Img_5 from "../../../assets/images/gallery/2024/tamil department/Img (5).png";

import SymposiumCA_Img_1 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (1).jpeg";
import SymposiumCA_Img_2 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (2).jpeg";
import SymposiumCA_Img_3 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (3).jpeg";
import SymposiumCA_Img_4 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (4).jpeg";
import SymposiumCA_Img_5 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (5).jpeg";
import SymposiumCA_Img_6 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (6).jpeg";
import SymposiumCA_Img_7 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (7).jpeg";
import SymposiumCA_Img_8 from "../../../assets/images/gallery/2024/Bcom ca sym/Img (8).jpeg";

import MedicalCampMB_Img_1 from "../../../assets/images/gallery/2024/mega camp/Img (1).jpeg";
import MedicalCampMB_Img_2 from "../../../assets/images/gallery/2024/mega camp/Img (2).jpeg";

import CsDigitalLiteracy_Img_1 from "../../../assets/images/gallery/2024/Computer science/Img (2).jpeg";
import CsDigitalLiteracy_Img_2 from "../../../assets/images/gallery/2024/Computer science/Img (3).jpeg";
import CsDigitalLiteracy_Img_3 from "../../../assets/images/gallery/2024/Computer science/Img (4).jpeg";
import CsDigitalLiteracy_Img_4 from "../../../assets/images/gallery/2024/Computer science/Img (5).jpeg";
import CsDigitalLiteracy_Img_5 from "../../../assets/images/gallery/2024/Computer science/Img (6).jpeg";
import CsDigitalLiteracy_Img_6 from "../../../assets/images/gallery/2024/Computer science/Img (7).jpeg";
import CsDigitalLiteracy_Img_7 from "../../../assets/images/gallery/2024/Computer science/Img (8).jpeg";
import CsDigitalLiteracy_Img_8 from "../../../assets/images/gallery/2024/Computer science/Img (9).jpeg";
import CsDigitalLiteracy_Img_9 from "../../../assets/images/gallery/2024/Computer science/Img (11).jpeg";

import ChemistryClub_Img_1 from "../../../assets/images/gallery/2024/chemistry club/Img  (1).jpeg";
import ChemistryClub_Img_2 from "../../../assets/images/gallery/2024/chemistry club/Img  (2).jpeg";
import ChemistryClub_Img_3 from "../../../assets/images/gallery/2024/chemistry club/Img  (3).jpeg";
import ChemistryClub_Img_4 from "../../../assets/images/gallery/2024/chemistry club/Img  (4).jpeg";
import ChemistryClub_Img_5 from "../../../assets/images/gallery/2024/chemistry club/Img  (5).jpeg";
import ChemistryClub_Img_6 from "../../../assets/images/gallery/2024/chemistry club/Img  (6).jpeg";
import ChemistryClub_Img_7 from "../../../assets/images/gallery/2024/chemistry club/Img  (7).jpeg";
import ChemistryClub_Img_8 from "../../../assets/images/gallery/2024/chemistry club/Img  (8).jpeg";
import ChemistryClub_Img_9 from "../../../assets/images/gallery/2024/chemistry club/Img  (9).jpeg";
import ChemistryClub_Img_10 from "../../../assets/images/gallery/2024/chemistry club/Img  (10).jpeg";

import BBAMiracle_Img_1 from "../../../assets/images/gallery/2024/BBA Mircale/IMG (1).jpeg";
import BBAMiracle_Img_2 from "../../../assets/images/gallery/2024/BBA Mircale/IMG (2).jpeg";
import BBAMiracle_Img_3 from "../../../assets/images/gallery/2024/BBA Mircale/IMG (4).jpeg";
import BBAMiracle_Img_4 from "../../../assets/images/gallery/2024/BBA Mircale/IMG (3).jpeg";
import BBAMiracle_Img_5 from "../../../assets/images/gallery/2024/BBA Mircale/IMG.jpeg";

import BCAClub_Img_1 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (1).jpeg";
import BCAClub_Img_2 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (2).jpeg";
import BCAClub_Img_3 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (3).jpeg";
import BCAClub_Img_4 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (4).jpeg";
import BCAClub_Img_5 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (5).jpeg";
import BCAClub_Img_6 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (6).jpeg";
import BCAClub_Img_7 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (7).jpeg";
import BCAClub_Img_8 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (8).jpeg";
import BCAClub_Img_9 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (9).jpeg";
import BCAClub_Img_10 from "../../../assets/images/gallery/2024/BCA Digital dynamo/IMG (10).jpeg";

import Mathematicsclub_Img_1 from "../../../assets/images/gallery/2024/maths/Img  (1).jpeg";
import Mathematicsclub_Img_2 from "../../../assets/images/gallery/2024/maths/Img  (2).jpeg";
import Mathematicsclub_Img_3 from "../../../assets/images/gallery/2024/maths/Img  (3).jpeg";
import Mathematicsclub_Img_4 from "../../../assets/images/gallery/2024/maths/Img  (4).jpeg";
import Mathematicsclub_Img_5 from "../../../assets/images/gallery/2024/maths/Img  (5).jpeg";
import Mathematicsclub_Img_6 from "../../../assets/images/gallery/2024/maths/Img  (6).jpeg";
import Mathematicsclub_Img_7 from "../../../assets/images/gallery/2024/maths/Img  (7).jpeg";
import Mathematicsclub_Img_8 from "../../../assets/images/gallery/2024/maths/Img  (8).jpeg";
import Mathematicsclub_Img_9 from "../../../assets/images/gallery/2024/maths/Img  (9).jpeg";
import Mathematicsclub_Img_10 from "../../../assets/images/gallery/2024/maths/Img  (10).png";

import PhysicsClub_Img_1 from "../../../assets/images/gallery/2024/physics/img  (1).jpeg";
import PhysicsClub_Img_2 from "../../../assets/images/gallery/2024/physics/img  (2).jpeg";
import PhysicsClub_Img_3 from "../../../assets/images/gallery/2024/physics/img  (3).jpeg";
import PhysicsClub_Img_4 from "../../../assets/images/gallery/2024/physics/img  (4).jpeg";
import PhysicsClub_Img_5 from "../../../assets/images/gallery/2024/physics/img  (5).jpeg";
import PhysicsClub_Img_6 from "../../../assets/images/gallery/2024/physics/img  (6).jpeg";
import PhysicsClub_Img_7 from "../../../assets/images/gallery/2024/physics/img  (7).jpeg";
import PhysicsClub_Img_8 from "../../../assets/images/gallery/2024/physics/img  (8).png";

import  CommerceFreshersDay_Img_1 from "../../../assets/images/gallery/2024/freshers day bcom/Img (1).jpeg";
import  CommerceFreshersDay_Img_2 from "../../../assets/images/gallery/2024/freshers day bcom/Img (2).jpeg";
import  CommerceFreshersDay_Img_3 from "../../../assets/images/gallery/2024/freshers day bcom/Img (3).jpeg";
import  CommerceFreshersDay_Img_4 from "../../../assets/images/gallery/2024/freshers day bcom/Img (4).jpeg";
import  CommerceFreshersDay_Img_5 from "../../../assets/images/gallery/2024/freshers day bcom/Img (5).jpeg";
import  CommerceFreshersDay_Img_6 from "../../../assets/images/gallery/2024/freshers day bcom/Img (6).jpeg";
import  CommerceFreshersDay_Img_7 from "../../../assets/images/gallery/2024/freshers day bcom/Img (7).jpeg";
import  CommerceFreshersDay_Img_8 from "../../../assets/images/gallery/2024/freshers day bcom/Img (8).jpeg";

import IndependenceDay78_Img_1 from "../../../assets/images/gallery/2024/Indepandance day/Img  (1).jpeg";
import IndependenceDay78_Img_2 from "../../../assets/images/gallery/2024/Indepandance day/Img  (2).jpeg";
import IndependenceDay78_Img_3 from "../../../assets/images/gallery/2024/Indepandance day/Img  (3).jpeg";
import IndependenceDay78_Img_4 from "../../../assets/images/gallery/2024/Indepandance day/Img  (4).jpeg";
import IndependenceDay78_Img_5 from "../../../assets/images/gallery/2024/Indepandance day/Img  (5).jpeg";
import IndependenceDay78_Img_6 from "../../../assets/images/gallery/2024/Indepandance day/Img  (6).jpeg";
import IndependenceDay78_Img_7 from "../../../assets/images/gallery/2024/Indepandance day/Img  (7).jpeg";
import IndependenceDay78_Img_8 from "../../../assets/images/gallery/2024/Indepandance day/Img  (8).jpeg";

import  MathsGingeeFort_Img_1 from "../../../assets/images/gallery/2024/Mathematics tour/Img.jpeg";

import   NextGenIntership_Img_1 from "../../../assets/images/gallery/2024/intenship/WhatsApp Image 2024-09-02 at 2.14.53 PM.jpeg";

import TeachersDay_Img_1 from "../../../assets/images/gallery/2024/teachers day/Img  (1).jpeg";
import TeachersDay_Img_2 from "../../../assets/images/gallery/2024/teachers day/Img  (2).jpeg";
import TeachersDay_Img_3 from "../../../assets/images/gallery/2024/teachers day/Img  (3).jpeg";
import TeachersDay_Img_4 from "../../../assets/images/gallery/2024/teachers day/Img  (4).jpeg";
import TeachersDay_Img_5 from "../../../assets/images/gallery/2024/teachers day/Img  (6).jpeg";
import TeachersDay_Img_6 from "../../../assets/images/gallery/2024/teachers day/Img  (7).jpeg";
import TeachersDay_Img_7 from "../../../assets/images/gallery/2024/teachers day/Img (5).jpeg";

import GuestLectureAICs_Img_1 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (1).jpeg";
import GuestLectureAICs_Img_2 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (2).jpeg";
import GuestLectureAICs_Img_3 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (3).jpeg";
import GuestLectureAICs_Img_4 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (4).jpeg";
import GuestLectureAICs_Img_5 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (5).jpeg";
import GuestLectureAICs_Img_6 from "../../../assets/images/gallery/2024/sept/cs dept overview AI/Img 1 (6).jpeg";

import GuestLectureEnglish_Img_1 from "../../../assets/images/gallery/2024/sept/english dept guest lecture/img  (1).jpeg";
import GuestLectureEnglish_Img_2 from "../../../assets/images/gallery/2024/sept/english dept guest lecture/img  (2).jpeg";
import GuestLectureEnglish_Img_3 from "../../../assets/images/gallery/2024/sept/english dept guest lecture/img  (3).jpeg";
import GuestLectureEnglish_Img_4 from "../../../assets/images/gallery/2024/sept/english dept guest lecture/img  (4).jpeg";
import GuestLectureEnglish_Img_5 from "../../../assets/images/gallery/2024/sept/english dept guest lecture/img  (5).jpeg";

import OnyxOpera24_Img_1 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (1).jpeg";
import OnyxOpera24_Img_2 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (3).jpeg";
import OnyxOpera24_Img_3 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (5).jpeg";
import OnyxOpera24_Img_4 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (7).jpeg";
import OnyxOpera24_Img_5 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (8).jpeg";
import OnyxOpera24_Img_6 from "../../../assets/images/gallery/2024/sept/onyx opero24/IMG  (9).jpeg";

import  OnamCelebrations_Img_1 from "../../../assets/images/gallery/2024/sept/onam festival/Img (1).jpeg";
import  OnamCelebrations_Img_2 from "../../../assets/images/gallery/2024/sept/onam festival/Img (2).jpeg";
import  OnamCelebrations_Img_3 from "../../../assets/images/gallery/2024/sept/onam festival/Img (3).jpeg";
import  OnamCelebrations_Img_5 from "../../../assets/images/gallery/2024/sept/onam festival/Img (5).jpeg";
import  OnamCelebrations_Img_6 from "../../../assets/images/gallery/2024/sept/onam festival/Img (6).jpeg";
import  OnamCelebrations_Img_7 from "../../../assets/images/gallery/2024/sept/onam festival/Img (7).jpeg";
import  OnamCelebrations_Img_8 from "../../../assets/images/gallery/2024/sept/onam festival/Img (8).jpeg";

import NaanMudhalvan2024_Img_1 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (1).jpeg";
import NaanMudhalvan2024_Img_2 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (2).jpeg";
import NaanMudhalvan2024_Img_3 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (3).jpeg";
import NaanMudhalvan2024_Img_4 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (4).jpeg";
import NaanMudhalvan2024_Img_5 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (5).jpeg";
import NaanMudhalvan2024_Img_6 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (6).jpeg";
import NaanMudhalvan2024_Img_7 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (7).jpeg";
import NaanMudhalvan2024_Img_8 from "../../../assets/images/gallery/2024/naan mudhal van/Img  (8).jpeg";

import TernBiotech_Img_1 from "../../../assets/images/gallery/2024/traning pondichery/Img  (1).jpeg";
import TernBiotech_Img_2 from "../../../assets/images/gallery/2024/traning pondichery/Img  (2).jpeg";
import TernBiotech_Img_3 from "../../../assets/images/gallery/2024/traning pondichery/Img  (3).jpeg";
import TernBiotech_Img_4 from "../../../assets/images/gallery/2024/traning pondichery/Img  (4).jpeg";

import PaperPresentions_Img_1 from "../../../assets/images/gallery/2024/Dhanalaskmini college/img  (1).jpeg";
import PaperPresentions_Img_2 from "../../../assets/images/gallery/2024/Dhanalaskmini college/img  (2).jpeg";
import PaperPresentions_Img_3 from "../../../assets/images/gallery/2024/Dhanalaskmini college/img  (3).jpeg";
import PaperPresentions_Img_4 from "../../../assets/images/gallery/2024/Dhanalaskmini college/img  (4).jpeg";
import PaperPresentions_Img_5 from "../../../assets/images/gallery/2024/Dhanalaskmini college/img  (5).jpeg";

import LawCamp_Img_1  from "../../../assets/images/gallery/2024/oct17/Img  (1).jpeg";
import LawCamp_Img_2  from "../../../assets/images/gallery/2024/oct17/Img  (2).jpeg";
import LawCamp_Img_3  from "../../../assets/images/gallery/2024/oct17/Img  (4).jpeg";
import LawCamp_Img_4  from "../../../assets/images/gallery/2024/oct17/Img  (8).jpeg";
import LawCamp_Img_5  from "../../../assets/images/gallery/2024/oct17/Img  (5).jpeg";
import LawCamp_Img_6  from "../../../assets/images/gallery/2024/oct17/Img  (6).jpeg";
import LawCamp_Img_7  from "../../../assets/images/gallery/2024/oct17/Img  (7).jpeg";



const Moments2024 = () => {
  const InternationalConference = [
    {
      image: InternationalConference_Img_1,
      altText: "",
      caption: "",
      id: "internalconference",
    },
    {
      image: InternationalConference_Img_2,
      altText: "",
      caption: "",
      id: "internalconference",
    },
    {
      image: InternationalConference_Img_3,
      altText: "",
      caption: "",
      id: "internalconference",
    },
    {
      image: InternationalConference_Img_4,
      altText: "",
      caption: "",
      id: "internalconference",
    },
    {
      image: InternationalConference_Img_5,
      altText: "",
      caption: "",
      id: "internalconference",
    },
    {
      image: InternationalConference_Img_6,
      altText: "",
      caption: "",
      id: "internalconference",
    },
  ];
  const PongalFestival = [
    {
      image: PongalFestival_Img_1,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_2,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_3,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_4,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_5,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_6,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
    {
      image: PongalFestival_Img_7,
      caption:
        " Career Counseling on Agriculture opportunities in Tamilnadu !!!",
      id: "pongalfestival",
    },
  ];
  const PosterPresention = [
    {
      image: PosterPresention_Img_1,
      altText: "",
      caption: "",
      id: "posterpresention",
    },
  ];
  const IndustrialVisit = [
    {
      image: IndustrialVisit_Img_1,
      altText: "",
      caption: "",
      id: "posterpresention",
    },
  ];
  const FoodFestival = [
    {
      image: FoodFestival_Img_1,
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_2,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_3,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_4,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_5,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_6,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_7,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
    {
      image: FoodFestival_Img_8,
      altText: "",
      caption: "",
      id: "foodfestival",
    },
  ];

  const YogaDay = [
    {
      image: YogaDay_Img_1,
      id: "yogaday",
    },
    {
      image: YogaDay_Img_2,
      id: "yogaday",
    },
    {
      image: YogaDay_Img_3,
      id: "yogaday",
    },
    {
      image: YogaDay_Img_4,
      id: "yogaday",
    },
    {
      image: YogaDay_Img_5,
      id: "yogaday",
    },
    {
      image: YogaDay_Img_6,
      id: "yogaday",
    },
  ];
  const SportsDazzle = [
    {
      image: SportsDazzle_Img_1,
      id: "sportday",
    },
    {
      image: SportsDazzle_Img_3,
      id: "sportday",
    },

    {
      image: SportsDazzle_Img_2,
      id: "sportday",
    },

    {
      image: SportsDazzle_Img_4,
      id: "sportday",
    },

    {
      image: SportsDazzle_Img_5,
      id: "sportday",
    },

    {
      image: SportsDazzle_Img_6,
      id: "sportday",
    },
  ];
  const FoodFestivalJune = [
    {
      image: FoodFestivaljune_Img_1,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_2,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_3,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_4,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_5,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_6,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_7,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_8,
      id: "june",
    },
    {
      image: FoodFestivaljune_Img_9,
      id: "june",
    },
  ];
  const FoodFestivalJune1 = [
    {
      image: FoodFestivaljune1_Img_1,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_2,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_3,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_4,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_5,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_6,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_7,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_8,
      id: "june",
    },
    {
      image: FoodFestivaljune1_Img_9,
      id: "june",
    },
  ];
  const UGInauguration = [
    {
      image: UGInauguration_Img_1,
      id: "uginauguration",
    },
    {
      image: UGInauguration_Img_2,
      id: "uginauguration",
    },
    {
      image: UGInauguration_Img_3,
      id: "uginauguration",
    },
    {
      image: UGInauguration_Img_4,
      id: "uginauguration",
    },
    {
      image: UGInauguration_Img_5,
      id: "uginauguration",
    },
    {
      image: UGInauguration_Img_6,
      id: "uginauguration",
    },
  ];
  const UGOrientationProgramme = [
    {
      image: UGOrientationProgramme_Img_1,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_2,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_3,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_4,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_5,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_6,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_7,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_8,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_9,
      id: "ugOrientation",
    },
    {
      image: UGOrientationProgramme_Img_10,
      id: "ugOrientation",
    },
  ];
  const AwernessProgramm = [
    {
      image: AwernessProgramm_Img_1,
      id: "socialwelfare",
    },
    {
      image: AwernessProgramm_Img_2,
      id: "socialwelfare",
    },
    {
      image: AwernessProgramm_Img_3,
      id: "socialwelfare",
    },
    {
      image: AwernessProgramm_Img_4,
      id: "socialwelfare",
    },
  ];
  const Kamaraj = [
    {
      image: Kamaraj_Img_1,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_2,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_3,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_4,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_5,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_6,
      id: "kamaraj",
    },
    {
      image: Kamaraj_Img_7,
      id: "kamaraj",
    },
  ];
  const ComputerApplication = [
    {
      image: ComputerApplication_Img_1,
      id: "ca ",
    },
  ];
  const EnglishDepartment = [
    {
      image: EnglishDepartment_Img_1,
      id: "english",
    },
  ];

  const TamilDepartment = [
    {
      image: TamilDepartment_Img_1,
      id: "tamil",
    },
    {
      image: TamilDepartment_Img_2,
      id: "tamil",
    },
    {
      image: TamilDepartment_Img_3,
      id: "tamil",
    },
    {
      image: TamilDepartment_Img_4,
      id: "tamil",
    },
    {
      image: TamilDepartment_Img_5,
      id: "tamil",
    },
  ];
  const Microbiology = [
    {
      image: Microbiology_Img_1,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_2,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_3,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_4,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_5,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_6,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_7,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_8,
      id: "microbiology",
    },
    {
      image: Microbiology_Img_9,
      id: "microbiology",
    },
  ];
  const SymposiumCA = [
    {
      image: SymposiumCA_Img_1,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_2,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_3,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_4,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_5,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_6,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_7,
      id: "symposiumca",
    },
    {
      image: SymposiumCA_Img_8,
      id: "symposiumca",
    },
  ];

  const MedicalCampMB = [
    {
      image: MedicalCampMB_Img_1,
      id: "medicalcampmb",
    },
    {
      image: MedicalCampMB_Img_2,
      id: "medicalcampmb",
    },
  ];

  const CsDigitalLiteracy = [
    {
      image: CsDigitalLiteracy_Img_1,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_2,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_3,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_4,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_5,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_6,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_7,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_8,
      id: "csdigitalliteracy",
    },
    {
      image: CsDigitalLiteracy_Img_9,
      id: "csdigitalliteracy",
    },
  ];

  const ChemistryClub = [
    {
      image: ChemistryClub_Img_1,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_2,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_3,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_4,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_5,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_6,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_7,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_8,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_9,
      id: "chemistryclub",
    },
    {
      image: ChemistryClub_Img_10,
      id: "chemistryclub",
    },
  ];

  const BBAMiracle = [
    {
      image: BBAMiracle_Img_1,
      id: "bbamiracle",
    },
    {
      image: BBAMiracle_Img_2,
      id: "bbamiracle",
    },
    {
      image: BBAMiracle_Img_3,
      id: "bbamiracle",
    },
    {
      image: BBAMiracle_Img_4,
      id: "bbamiracle",
    },
    {
      image: BBAMiracle_Img_5,
      id: "bbamiracle",
    },
  ];

  const BCAClub = [
    {
      image: BCAClub_Img_1,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_2,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_3,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_4,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_6,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_5,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_7,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_8,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_9,
      id: "bcaclub",
    },
    {
      image: BCAClub_Img_10,
      id: "bcaclub",
    },
  ];

  const Mathematicsclub = [
    {
      image: Mathematicsclub_Img_1,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_2,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_3,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_4,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_6,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_5,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_7,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_8,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_9,
      id: "mathematicsclub",
    },
    {
      image: Mathematicsclub_Img_10,
      id: "mathematicsclub",
    },
  ];

  const PhysicsClub = [
    {
      image: PhysicsClub_Img_1,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_2,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_3,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_4,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_5,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_6,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_7,
      id: "physicsclub",
    },
    {
      image: PhysicsClub_Img_8,
      id: "physicsclub",
    },
  ];

  const CommerceFreshersDay = [
       {
      image: CommerceFreshersDay_Img_1,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_2,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_3,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_4,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_6,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_5,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_7,
      id: "commercefreshersday",
    },
    {
      image: CommerceFreshersDay_Img_8,
      id: "commercefreshersday",
    },
  ];

  const IndependenceDay78 =[
    {
      image:  IndependenceDay78_Img_1,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_2,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_3,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_4,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_6,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_5,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_7,
      id: "independenceday",
    },
    {
      image:  IndependenceDay78_Img_8,
      id: "independenceday",
    },

  ];

  const MathsGingeeFort =[
    {
      image: MathsGingeeFort_Img_1,
      id: "mathsgingeefort",
    },
  ];

  const NextGenIntership=[
    {
      image: NextGenIntership_Img_1,
      id: "nextgenintership",
    },
  ];

  const TeachersDay =[
    {
      image: TeachersDay_Img_1,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_2,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_3,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_4,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_5,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_6,
      id: "teachersday",
    },
    {
      image: TeachersDay_Img_7,
      id: "teachersday",
    },
  ];
 
  const GuestLectureAICs =[
    {
     image: GuestLectureAICs_Img_1,
      id: "guestlectureaics",
    },
    {
      image: GuestLectureAICs_Img_2,
       id: "guestlectureaics",
     },
     {
      image: GuestLectureAICs_Img_3,
       id: "guestlectureaics",
     },
     {
      image: GuestLectureAICs_Img_5,
       id: "guestlectureaics",
     },
     {
      image: GuestLectureAICs_Img_4,
       id: "guestlectureaics",
     },
     {
      image: GuestLectureAICs_Img_6,
       id: "guestlectureaics",
     },
  ];

  const GuestLectureEnglish =[
    {
      image: GuestLectureEnglish_Img_1,
       id: "guestlectureenglish",
     },
     {
      image: GuestLectureEnglish_Img_2,
       id: "guestlectureenglish",
     },
     {
      image: GuestLectureEnglish_Img_3,
       id: "guestlectureenglish",
     },
     {
      image: GuestLectureEnglish_Img_4,
       id: "guestlectureenglish",
     },
     {
      image: GuestLectureEnglish_Img_5,
       id: "guestlectureenglish",
     },
  ];
  
  const OnyxOpera24 =[
    {
      image: OnyxOpera24_Img_1,
       id: "onyxopera",
     },
     {
      image: OnyxOpera24_Img_2,
       id: "onyxopera",
     },
     {
      image: OnyxOpera24_Img_3,
       id: "onyxopera",
     },
     {
      image: OnyxOpera24_Img_4,
       id: "onyxopera",
     },
     {
      image: OnyxOpera24_Img_5,
       id: "onyxopera",
     },
     {
      image: OnyxOpera24_Img_6,
       id: "onyxopera",
     },
  ];
   
  const OnamCelebrations =[
    {
      image: OnamCelebrations_Img_1,
      id: "onam",
    },
    {
      image: OnamCelebrations_Img_2,
      id: "onam",
    },
    {
      image: OnamCelebrations_Img_3,
      id: "onam",
    },
      {
      image: OnamCelebrations_Img_5,
      id: "onam",
    },
    {
      image: OnamCelebrations_Img_6,
      id: "onam",
    },
    {
      image: OnamCelebrations_Img_7,
      id: "onam",
    },
    {
      image: OnamCelebrations_Img_8,
      id: "onam",
    },
  ];
  const NaanMudhalvan2024=[
    {
      image: NaanMudhalvan2024_Img_1,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_2,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_3,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_4,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_5,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_6,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_7,
      id: "naanmudhalvan",
    },
    {
      image: NaanMudhalvan2024_Img_8,
      id: "naanmudhalvan",
    },
  ];
  const TernBiotech =[
    {
      image:TernBiotech_Img_1,
      id: "ternbiotech",
    },
    {
      image:TernBiotech_Img_2,
      id: "ternbiotech",
    },
    {
      image:TernBiotech_Img_3,
      id: "ternbiotech",
    },
    {
      image:TernBiotech_Img_4,
      id: "ternbiotech",
    },
   
  ];

  const PaperPresentions=[
    {
      image:PaperPresentions_Img_1,
      id: "ppt",
    },
    {
      image:PaperPresentions_Img_2,
      id: "ppt",
    },
    {
      image:PaperPresentions_Img_3,
      id: "ppt",
    },
    {
      image:PaperPresentions_Img_4,
      id: "ppt",
    },
    {
      image:PaperPresentions_Img_5,
      id: "ppt",
    },
  ];

  const LawCamp =[
    {
      image:LawCamp_Img_1,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_2,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_3,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_4,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_5,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_6,
      id: "lawcamp",
    },
    {
      image:LawCamp_Img_7,
      id: "lawcamp",
    },
  ];
  return (
    <>
      <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

      <div className="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h4 class="section-title bg-white text-center text-primary px-3">
            Gallery
          </h4>
          <h4 class="mb-5"> JNCW-2024 Moments </h4>
        </div>
        <EventContainer
          data={LawCamp}
          header="சட்ட விழப்புணர் முகாம் "
          id="lawcamp"
        />
         <EventContainer
          data={PaperPresentions}
          header="In our Microbiology department Students on 03.10.2024 went to Dhanalakshmi Srinivasan Arts and Science College - Perambalur to participate in the Paper Presentation and Poster Presentation."
          id="ppt"
        />
         <EventContainer
          data={TernBiotech}
          header="Our II year B. Sc Microbiology students went to Pondicherry TERN BIOTECH to attend a one-day Hands-on Training Program - 26.09.2024"
          id="ternbiotech"
        />
         <EventContainer
          data={NaanMudhalvan2024}
          header="நான் முதல்வன் -உயர்வுக்கு பாடி 23-09-2024"
          id="naanmudhalvan"
        />

        <EventContainer
          data={OnamCelebrations}
          header="Onam Festival Celebrations"
          id="onam"
        />

        <EventContainer
          data={OnyxOpera24}
          header=" Onyx Opera -2024"
          id="onyxopera"
        />
         <EventContainer
          data={GuestLectureEnglish}
          header="The Department of English conducted the guest lecture on English language teaching through the English literature resource person presented by Dr. Immanuvel Sagaya Selvam, vice _principal of C.K School of Practical Knowledge, which lecture more helpful for students on how to improve communication skills through English literature. "
          id="guestlectureenglish"
        />
        <EventContainer
          data={GuestLectureAICs}
          header="Department of Computer Science and Application Conducted guest lecture program for the Overview of AI"
          id="guestlectureaics"
        />

        <EventContainer
          data={TeachersDay}
          header="Teacher's Day celebrations "
          id="teachersday"
        />

        <EventContainer
          data={NextGenIntership}
          header="III BCA students went Internship at pondicherry NextGen technology."
          id="nextgenintership"
        />




        <EventContainer
          data={MathsGingeeFort}
          header="Department of Mathematics Conducted an tour at Gingee Fort."
          id="mathsgingeefort"
        />
        
        <EventContainer
          data={IndependenceDay78}
          header="78'th Independence Day Celebration"
          id="independenceday"
        />

        <EventContainer
          data={CommerceFreshersDay}
          header="Department of Commerce (computer application) has celebrated freshers day "
          id="commercefreshersday"
        />
        
        <EventContainer
          data={PhysicsClub}
          header="Department of Physics Conducted Vikram Sarabhai Club Inauguration"
          id="physicsClub"
        />

        <EventContainer
          data={Mathematicsclub}
          header="Department of Mathematics Conducted Sakuntala devi Club Inauguration"
          id="mathematicsclub"
        />

        <EventContainer
          data={BCAClub}
          header="Department of BCA Organized the DIGITAL DYNAMOS CLUB"
          id="bcaclub"
        />

        <EventContainer
          data={BBAMiracle}
          header="Department of BBA Miracle Makers Service Club conducted Pratyagra 2k24"
          id="bbamiracle"
        />

        <EventContainer
          data={ChemistryClub}
          header="Department of Chemistry conducted Dmitri's Chemical Club inauguration, PPT, Posterpresentation and Science exhibition."
          id="chemistryclub"
        />

        <EventContainer
          data={CsDigitalLiteracy}
          header="Department of Computer Science for Digital Literacy Club Inauguration"
          id="csdigitalliteracy"
        />

        <EventContainer
          data={MedicalCampMB}
          header="Department of Microbiology Micro Miracles Club organised a Medical Camp"
          id="medicalcampmb"
        />

        <EventContainer
          data={SymposiumCA}
          header="One Day Technical Symposium Department of Commerce (Computer Application) - CA -KIKSTA 2K24 "
          id="symposiumca"
        />

        <EventContainer
          data={TamilDepartment}
          header="இளந்தென்றல் இலக்கியமன்ற துவக்கவிழா "
          id="tamil"
        />

        <EventContainer
          data={Microbiology}
          header="Department of Microbiology Micro-Miracles Club Inauguration, PPT and Poster Presentation on 19.07.2024"
          id="microbiology"
        />

        <EventContainer
          data={ComputerApplication}
          header="The Department of Commerce (Computer Application) Has inaugurated our Commerce warrior club, by our department student Association council and our students posted their posters with creativity."
          id="ca"
        />

        <EventContainer
          data={EnglishDepartment}
          header="The English Department held a Prologue and Epilogue Writing event for the Gitanjali Literary Association."
          id="english"
        />

        <EventContainer
          data={Kamaraj}
          header="காமராஜர் பிறந்த நாள் விழா "
          caption="15.07.2024 இன்று காமராஜர் பிறந்த நாள் விழா தமிழ்த்துறையில்  கொண்டாடப்பட்டு அனைத்துத் துறை முதலாமாண்டு மாணவர்களுக்கும் இனிப்புகள் வழங்கப்பட்டது"
          id="kamaraj"
        />

        <EventContainer
          data={AwernessProgramm}
          header="The Social Welfare and awareness program"
          id="socialwelfare"
        />

        <EventContainer
          data={UGOrientationProgramme}
          header="FRESH FIESTA'24 - I year UG Orientation Programme"
          id="ugOrientation}"
        />

        <EventContainer
          data={UGInauguration}
          header="FRESH FIESTA'24 - I year UG Inauguration"
          id="uginauguration"
        />

        <EventContainer
          data={FoodFestivalJune1}
          header="Food Festival June -2024"
          id="june1"
        />
        <EventContainer
          data={FoodFestivalJune}
          header="Food Festival June -2024"
          id="june"
        />
        <EventContainer
          data={YogaDay}
          header="International Yoga Day-2024"
          id="yogaday"
        />
        <EventContainer
          data={SportsDazzle}
          header="Sports Day Dazzle - 2024 "
          id="sportday"
        />

        <EventContainer
          data={InternationalConference}
          header="International Conference Organized by the Science Departments"
          id="internalconference"
        />
        <EventContainer
          data={IndustrialVisit}
          header=' Department of Commerce (Computer Application) went to the "Solar Observatory Museum" at Kodaikanal - Industrial Visit as a part of their Curriculum and visited So many places at Kodaikanal as their Educational Tour!'
          id="indutrialvistit"
        />
        <EventContainer
          data={PosterPresention}
          header="Poster Presentions Jan-2024"
          id="posterpresention"
        />

        <EventContainer
          data={PongalFestival}
          header="Pongal Festival-2024"
          id="pongalfestival"
        />

        <EventContainer
          data={FoodFestival}
          header="Food Festival -2024"
          id="foodfestival"
        />

      </div>
    </>
  );
};

export default Moments2024;
